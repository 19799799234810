export const colors = [
  { name: "Yellow", value: "#F6E3BB" },
  { name: "Red", value: "#F5BBBB" },
  { name: "Blue", value: "#BEE5F6" },
  { name: "Green", value: "#B9F4D0" },
  { name: "Purple", value: "#DBD5F4" },
];
export const types = [
  { name: "Square", value: "square" },
  { name: "Circle", value: "circle" },
];
